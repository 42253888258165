<template>
	<!-- 变形报警 -->
	<div class="page-box"></div>
</template>

<script>
export default {
	name: '',
	data() {
		return {};
	},
	created() {},
	mounted() {},
	computed: {},
	methods: {},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss"></style>
